import React from "react";

import { Box } from "@mui/material";

import ChildSeatChip from "./ChildSeatChip";
import { ChildSeatVariant } from "types";

const { RearFacing, ForwardFacing, Booster } = ChildSeatVariant;

type ChildSeatsNoteBlockProps = {
  boosterSeatQuantity?: number;
  forwardFacingSeatQuantity?: number;
  rearFacingSeatQuantity?: number;
};

function ChildSeatsNoteBlock(props: ChildSeatsNoteBlockProps) {
  const {
    rearFacingSeatQuantity,
    boosterSeatQuantity,
    forwardFacingSeatQuantity,
  } = props;

  return (
    <Box display="flex" flexWrap="wrap" columnGap={1} mt={1}>
      <ChildSeatChip variant={RearFacing} quantity={rearFacingSeatQuantity} />
      <ChildSeatChip
        variant={ForwardFacing}
        quantity={forwardFacingSeatQuantity}
      />
      <ChildSeatChip variant={Booster} quantity={boosterSeatQuantity} />
    </Box>
  );
}

export default ChildSeatsNoteBlock;
