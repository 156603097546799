/**
 * @file graphql/operatorRoutes
 * queries/mutations for operatorRoutes.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_OPERATOR_ROUTE_QUERY = gql`
  query driverRoute($operatorRouteId: ID!) {
    driverRoute(operatorRouteId: $operatorRouteId) {
      id
      orderNumber
      operatorConfirmation
      driverCloseoutStartDateTime
      driverCloseoutEndDateTime
      driverCloseoutDurationMinutes
      driverCloseoutNote
      amountDue
      driverStatus {
        name
      }
      request {
        id
        orderNumber
        type
        bookingContact {
          id
          createdAt
          updatedAt
          firstName
          lastName
          email
          mobilePhone
        }
        company {
          id
          name
        }
      }
      alert {
        newDriver
        uniqueLocation
        airport
        largeVehicle
        partner
        luggage
        signage
        carSeat
      }
      driver {
        id
        firstName
        lastName
        mobilePhone
        email
      }
      vehicle {
        id
        name
        licensePlate
        vehicleType {
          typeName
        }
        operator {
          id
          name
          generalEmail
          companyLogoUrl
        }
        photos {
          id
          url
          photoIndex
        }
      }
      statusSlug
      driverNote
      trip {
        id
        requestId
        note
        childSeats
        totalGroupSize
        totalDuration
        estimatedDuration
        useTotalDuration
        tripNumber
        tripCategory
        temporaryPassenger
        cancelledAt
        routes {
          id
          createdAt
          updatedAt
          isFarmedRoute
          farmRelationship
          farmAffiliateVariant
          farmAffiliate {
            ... on ExternalOperator {
              id
              operatorName
              operatorPhone
            }
            ... on MoovsNetworkOperator {
              id
              operatorName
              operatorPhone
            }
            ... on GriddnetOperator {
              id
              operatorName
              operatorPhone
            }
          }
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          vehicle {
            id
            name
            capacity
            vehicleType {
              typeName
              typeSlug
            }
            description
            photos {
              id
              url
              photoIndex
            }
          }
          carryOnLuggage
          checkedLuggage
          oversizeLuggage
        }
        stops {
          id
          stopIndex
          location
          locationAlias
          dateTime
          logisticStreetViewUrl
          note
          groupSize
          flightNumber
          variant
          pickUpVariant
          originalDateTime
          flightOffset
          trackedFlight {
            id
            faFlightId
            updatedAt
            airline {
              airlineName
              iataCode
            }
            actualAirline {
              airlineName
              iataCode
            }
            flightStatus
            flightNumber
            actualFlightNumber
            origin {
              id
              airport {
                airportName
                iataCode
                icaoCode
                countryName
                countryIso2
                timezone
                gmt
              }
              scheduledGate
              scheduledDateTime
              scheduledTerminal
              actualGate
              actualDateTime
              estimatedDateTime
              actualTerminal
            }
            destination {
              id
              airport {
                airportName
                iataCode
                icaoCode
                countryName
                countryIso2
                timezone
                gmt
              }
              scheduledGate
              scheduledDateTime
              scheduledTerminal
              actualGate
              estimatedDateTime
              actualDateTime
              actualTerminal
            }
          }
          airport {
            icaoCode
            iataCode
            coordinates
            airportName
            countryName
            countryIso2
            timezone
            gmt
          }
          airline {
            icaoCode
            iataCode
            airlineName
            callsign
            fleetSize
            countryName
            countryIso2
            dateFounded
          }
        }
        contact {
          id
          firstName
          lastName
          email
          mobilePhone
        }
      }
      operator {
        id
        name
        voicePhoneNumber
        companyLogoUrl
      }
    }
  }
`;

export const LOAD_OPERATOR_ROUTES_QUERY = gql`
  query OperatorRoutes($driverId: ID!, $tabName: TabName!) {
    operatorRoutes(driverId: $driverId, tabName: $tabName) {
      edges {
        node {
          id
          orderNumber
          operatorConfirmation
          driverCloseoutStartDateTime
          driverCloseoutEndDateTime
          driverCloseoutDurationMinutes
          driverCloseoutNote
          driverStatus {
            name
          }
          request {
            id
            orderNumber
            type
            bookingContact {
              id
              createdAt
              updatedAt
              firstName
              lastName
              email
              mobilePhone
            }
          }
          statusSlug
          driverNote
          operator {
            id
          }
          alert {
            newDriver
            uniqueLocation
            airport
            largeVehicle
            partner
            luggage
            signage
            carSeat
          }
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          vehicle {
            id
            capacity
            name
          }
          trip {
            id
            requestId
            totalGroupSize
            totalDuration
            estimatedDuration
            useTotalDuration
            tripNumber
            temporaryPassenger
            contact {
              id
              firstName
              lastName
              mobilePhone
            }
            stops {
              id
              dateTime
              location
              locationAlias
              stopIndex
              flightNumber
              note
              groupSize
              variant
              trackedFlight {
                id
                faFlightId
                updatedAt
                airline {
                  airlineName
                  iataCode
                }
                actualAirline {
                  airlineName
                  iataCode
                }
                flightStatus
                flightNumber
                actualFlightNumber
                origin {
                  id
                  airport {
                    airportName
                    iataCode
                    icaoCode
                    countryName
                    countryIso2
                    timezone
                    gmt
                  }
                  scheduledGate
                  scheduledDateTime
                  scheduledTerminal
                  actualGate
                  actualDateTime
                  estimatedDateTime
                  actualTerminal
                }
                destination {
                  id
                  airport {
                    airportName
                    iataCode
                    icaoCode
                    countryName
                    countryIso2
                    timezone
                    gmt
                  }
                  scheduledGate
                  scheduledDateTime
                  scheduledTerminal
                  actualGate
                  estimatedDateTime
                  actualDateTime
                  actualTerminal
                }
              }
              airport {
                icaoCode
                iataCode
                airportName
                countryName
                countryIso2
                timezone
                gmt
              }
              airline {
                icaoCode
                iataCode
                airlineName
                callsign
                fleetSize
                countryName
                countryIso2
                dateFounded
              }
            }
          }
        }
      }
    }
  }
`;

export const LOAD_OPERATOR_ROUTES_TOTAL_COUNT_QUERY = gql`
  query OperatorRoutesTotalCount($driverId: ID!) {
    operatorRoutesTotalCount(driverId: $driverId) {
      newTotalCount
      upcomingTotalCount
      completedTotalCount
    }
  }
`;

export const UPDATE_ROUTE_DISPATCH_MUTATION = gql`
  mutation UpdateRouteDispatch($input: UpdateRouteDispatchInput!) {
    updateRouteDispatch(input: $input) {
      operatorRoute {
        id
        orderNumber
        operatorConfirmation
        driverCloseoutStartDateTime
        driverCloseoutEndDateTime
        driverCloseoutDurationMinutes
        driverCloseoutNote
        driverStatus {
          name
        }
        statusSlug
        driverNote
        request {
          id
          orderNumber
          type
          bookingContact {
            id
            createdAt
            updatedAt
            firstName
            lastName
            email
            mobilePhone
          }
        }
        operator {
          id
        }
        alert {
          newDriver
          uniqueLocation
          airport
          largeVehicle
          partner
          luggage
          signage
          carSeat
        }
        driver {
          id
          firstName
          lastName
          mobilePhone
          email
        }
        vehicle {
          id
          capacity
          name
        }
        trip {
          id
          requestId
          totalGroupSize
          totalDuration
          estimatedDuration
          useTotalDuration
          contact {
            id
            firstName
            lastName
            mobilePhone
          }
          stops {
            id
            dateTime
            location
            locationAlias
            stopIndex
            flightNumber
            note
            groupSize
            variant
            airport {
              icaoCode
              iataCode
              airportName
              countryName
              countryIso2
              timezone
              gmt
            }
            airline {
              icaoCode
              iataCode
              airlineName
              callsign
              fleetSize
              countryName
              countryIso2
              dateFounded
            }
          }
        }
      }
    }
  }
`;

export const DRIVER_ACCEPT_OR_DECLINE_ROUTE_MUTATION = gql`
  mutation AcceptOrDeclineRoute($input: AcceptOrDeclineRouteInput!) {
    acceptOrDeclineRoute(input: $input) {
      operatorRoute {
        id
        orderNumber
        operatorConfirmation
        driverCloseoutStartDateTime
        driverCloseoutEndDateTime
        driverCloseoutDurationMinutes
        driverCloseoutNote
        driverStatus {
          name
        }
      }
    }
  }
`;

// ! deprecated 2022-03-23
export const ACCEPT_OPERATOR_ROUTE_MUTATION = gql`
  mutation AcceptOperatorRoute($input: AcceptOperatorRouteInput!) {
    acceptOperatorRoute(input: $input) {
      operatorRoute {
        id
        orderNumber
        operatorConfirmation
        driverCloseoutStartDateTime
        driverCloseoutEndDateTime
        driverCloseoutDurationMinutes
        driverCloseoutNote
        driverStatus {
          name
        }
      }
    }
  }
`;

// ! deprecated 2022-03-23
export const DECLINE_OPERATOR_ROUTE_MUTATION = gql`
  mutation DeclineOperatorRoute($input: DeclineOperatorRouteInput!) {
    declineOperatorRoute(input: $input) {
      operatorRoute {
        id
        orderNumber
        operatorConfirmation
        driverCloseoutStartDateTime
        driverCloseoutEndDateTime
        driverCloseoutDurationMinutes
        driverCloseoutNote
        driverStatus {
          name
        }
      }
    }
  }
`;

export const DRIVER_CLOSEOUT_ROUTE_MUTATION = gql`
  mutation DriverCloseoutRoute($input: DriverCloseoutRouteInput!) {
    driverCloseoutRoute(input: $input) {
      operatorRoute {
        id
        driverCloseoutStartDateTime
        driverCloseoutEndDateTime
        driverCloseoutNote
        driverCloseoutDurationMinutes
      }
    }
  }
`;
