import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { tintGreen, white } from "globals/design-system/colors";
import { MessageIcon, PhoneIcon } from "globals/design-system/icons";
import { CircleButton } from "components/ui";
import { FarmAffiliate } from "types";

type FarmBlockProps = { farmAffiliate: FarmAffiliate };

function FarmBlock(props: FarmBlockProps) {
  const { farmAffiliate } = props;
  const { operatorName, operatorPhone } = farmAffiliate;

  return (
    <Box
      bgcolor={tintGreen}
      p={2}
      display="flex"
      flexDirection="column"
      flex="1"
      borderRadius="4px"
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.03)"
    >
      <Typography variant="subtitle1">Farmed Trip</Typography>
      <Box py={1}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Typography variant="overline">affiliate</Typography>
          <Typography variant="body2">{operatorName}</Typography>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box mr={1.5}>
            <CircleButton
              disabled={!operatorPhone}
              component="a"
              href={`sms:${operatorPhone}`}
            >
              <MessageIcon color={white} />
            </CircleButton>
          </Box>
          <CircleButton
            disabled={!operatorPhone}
            component="a"
            href={`tel:${operatorPhone}`}
          >
            <PhoneIcon color={white} />
          </CircleButton>
        </Box>
      </Box>
    </Box>
  );
}

export default FarmBlock;
