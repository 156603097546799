import React from "react";

import { Box, Typography, Button } from "@mui/material";

import { white, moovsBlue } from "../../globals/design-system/colors";

type BottomButtonProps = {
  text: string;
  icon?: React.ReactChild;
  onClick: (event) => void;
};

function BottomButton(props: BottomButtonProps) {
  const { text, icon, onClick } = props;

  return (
    <Box
      position="fixed"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={white}
      bottom="0"
      width="100%"
      height="76px"
      boxShadow="0px 0px 30px rgba(30, 30, 30, 0.1);"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth="600px"
        width="100%"
        m={2}
        borderRadius="4px"
        bgcolor={moovsBlue}
        height="44px"
      >
        <Button fullWidth onClick={onClick}>
          {icon && (
            <Box mt="6px" mr={2}>
              {icon}
            </Box>
          )}
          <Typography align="center" variant="button" style={{ color: white }}>
            {text}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default BottomButton;
