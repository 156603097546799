/**
 * @file HeaderBlock.tsx
 * Header of driver dispatch view
 *
 * components:
 *  HeaderBlock
 */

import React from "react";
import moment from "moment-timezone";
import first from "lodash/first";

import { Box, Divider, Typography } from "@mui/material";
import {
  ClockIcon,
  EstimationIcon,
  TripIcon,
  UserIcon,
} from "globals/design-system/icons";
import { grayDark } from "globals/design-system/colors";
import { Trip } from "types";
import { convertMinutesToHoursMinutes, currency } from "globals/utils/helpers";
import FlightIcon from "globals/design-system/icons/info/FlightIcon";
import { IconInfoSegment } from "components/ui";

type HeaderBlockProps = {
  trip: Trip;
  tripType: string;
  amountDue: number;
};

function HeaderBlock(props: HeaderBlockProps) {
  const { trip, tripType, amountDue } = props;

  const pickUpStop = first(trip.stops);
  const pickUpOriginalTime = moment
    .utc(pickUpStop.originalDateTime)
    .format("h:mm A");
  const pickUpDate = moment.utc(pickUpStop.dateTime).format("M/DD/YY");
  const pickUpTime = moment.utc(pickUpStop.dateTime).format("h:mm A");
  const isDateTimeSame =
    moment
      .utc(pickUpStop.dateTime)
      .diff(moment.utc(pickUpStop.originalDateTime), "minutes") === 0;

  const shouldShowAmountDue = typeof amountDue === "number";

  return (
    <Box>
      <Box display="flex" flexDirection="row" mb={2}>
        <Box mr={2}>
          <Typography variant="overline" style={{ color: grayDark }}>
            Trip Date
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {pickUpDate}
          </Typography>
        </Box>
        <Box mr={2}>
          <Typography variant="overline" style={{ color: grayDark }}>
            Time
          </Typography>
          <Box>
            {pickUpStop.flightOffset && !isDateTimeSame ? (
              <>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                    textDecorationLine: "line-through",
                    color: grayDark,
                  }}
                >
                  {pickUpOriginalTime}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "bold", marginRight: 5 }}
                  >
                    {pickUpTime}
                  </Typography>
                  <FlightIcon size="small" />
                </Box>
              </>
            ) : (
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {pickUpTime}
              </Typography>
            )}
          </Box>
        </Box>
        {shouldShowAmountDue && (
          <Box>
            <Typography variant="overline" style={{ color: grayDark }}>
              Amount Due
            </Typography>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {currency(amountDue)}
            </Typography>
          </Box>
        )}
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box mr={1} mb={1}>
          <IconInfoSegment
            label="Passenger count"
            value={trip.totalGroupSize}
            icon={UserIcon}
          />
        </Box>
        <Box mr={1} mb={1}>
          <IconInfoSegment label="Trip Type" value={tripType} icon={TripIcon} />
        </Box>
        <Box mr={1} mb={1}>
          <IconInfoSegment
            label={
              trip.useTotalDuration ? "total duration" : "estimated duration"
            }
            value={
              trip.useTotalDuration ? (
                convertMinutesToHoursMinutes(trip.totalDuration)
              ) : (
                <Box display="flex" alignItems="center">
                  <Box mr={0.5}>
                    {convertMinutesToHoursMinutes(trip.estimatedDuration)}
                  </Box>
                  <EstimationIcon />
                </Box>
              )
            }
            icon={ClockIcon}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default HeaderBlock;
