import React, { useEffect, useState } from "react";
import min from "lodash/min";

import { Box, Drawer, IconButton, Typography, Zoom } from "@mui/material";

import { CrossIcon } from "globals/design-system/icons";
import { granite, grayLight } from "globals/design-system/colors";
import { GreetingSignText } from "./components";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// tenantively I put the start size to 25vw for the name - that seems a reasonable vw for most screens.
const DEFAULT_FONT_SIZE_IN_VW = 25;
const STEP_SIZE = 1;

type GreetingSignDrawerProps = {
  isSignDrawerOpen: boolean;
  greetingSignName: string;
  isTemporaryPassenger: boolean;
  onGreetingSignDrawerClose: () => void;
  companyName?: string;
};

function GreetingSignDrawer(props: GreetingSignDrawerProps) {
  const {
    isSignDrawerOpen,
    greetingSignName,
    isTemporaryPassenger,
    onGreetingSignDrawerClose,
    companyName,
  } = props;

  const passengerNameFirstPart = !isTemporaryPassenger
    ? greetingSignName
    : greetingSignName.substring(0, greetingSignName.indexOf(" "));

  const passengerNameSecondPart = isTemporaryPassenger
    ? greetingSignName.substring(greetingSignName.indexOf(" ") + 1)
    : "";

  // states
  const [isTransform90, setIsTransform90] = useState(
    window.matchMedia("(orientation: portrait)").matches ? true : false
  );
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isIconShow, setIsIconShow] = useState(false);
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timeout>();
  const [isNameSplit, setIsNameSplit] = useState(false);
  const [nameFirstPartWidth, setNameFirstPartWidth] = useState(0);
  const [nameSecondPartWidth, setNameSecondPartWidth] = useState(0);
  const [nameFirstPartFontSize, setNameFirstPartFontSize] = useState(
    DEFAULT_FONT_SIZE_IN_VW
  );
  const [nameSecondPartFontSize, setNameSecondPartFontSize] = useState(
    DEFAULT_FONT_SIZE_IN_VW
  );
  const [finalFontSize, setFinalFontSize] = useState(Infinity);

  const drawerWidth = isTransform90
    ? windowDimensions.height
    : windowDimensions.width;

  const drawerHeight = isTransform90
    ? windowDimensions.width
    : windowDimensions.height;

  //event handlers
  const handleSignDrawerAndIconClose = () => {
    onGreetingSignDrawerClose();
    setIsIconShow(false);
  };

  const handleShowCloseIcon = () => {
    setIsIconShow(true);
    if (timeOutId) {
      clearTimeout(timeOutId);
    }

    const newTimeOutId = setTimeout(() => {
      setIsIconShow(false);
    }, 3000);

    setTimeOutId(newTimeOutId);
  };

  // to lock the orientation in landscape mode
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());

      // matchmedia supported browsers: https://caniuse.com/matchmedia
      if (window.matchMedia("(orientation: portrait)").matches) {
        setIsTransform90(true);
      } else {
        setIsTransform90(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [setIsTransform90]);

  // to scale the font size of first and last name
  useEffect(() => {
    const changeFirstPart =
      nameFirstPartWidth > drawerWidth && nameFirstPartFontSize > 2;
    const changeSecondPart =
      nameSecondPartWidth &&
      nameSecondPartWidth > drawerWidth &&
      nameSecondPartFontSize > 2;
    const changeNameSplit = nameFirstPartFontSize < 12 && isTemporaryPassenger;
    const changeFinalFont =
      isNameSplit && nameSecondPartFontSize !== nameFirstPartFontSize;

    if (changeFirstPart) {
      setNameFirstPartFontSize((fontsize) => fontsize - STEP_SIZE);
    }
    if (changeSecondPart) {
      setNameFirstPartFontSize(DEFAULT_FONT_SIZE_IN_VW);
      setNameSecondPartFontSize((fontsize) => fontsize - STEP_SIZE);
    }
    if (changeNameSplit) {
      setIsNameSplit(true);
    }
    if (changeFinalFont) {
      setFinalFontSize(min([nameFirstPartFontSize, nameSecondPartFontSize]));
    }
  }, [
    nameFirstPartWidth,
    nameSecondPartWidth,
    drawerWidth,
    isTemporaryPassenger,
    nameFirstPartFontSize,
    nameSecondPartFontSize,
    isNameSplit,
  ]);

  // custom styles
  const greetingDrawer = {
    width: drawerWidth,
    height: drawerHeight,
    ...(isTransform90 && {
      transformOrigin: "top left",
      transform: "rotate(-90deg) translate(-100%)",
    }),
    overflow: "scroll",
  };
  const greetingTextContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: drawerWidth,
    // adding 1px to height to be able to scroll and in turn minimize the tab bar
    minHeight: drawerHeight + 1,
    maxHeight: drawerHeight + 1,
    padding: "-24px 24px",
  };

  return (
    <Drawer
      anchor="top"
      open={isSignDrawerOpen}
      onClickCapture={handleShowCloseIcon}
      sx={greetingDrawer}
    >
      <Zoom in={isIconShow}>
        <IconButton
          onClick={handleSignDrawerAndIconClose}
          size="medium"
          sx={{
            borderRadius: 50,
            backgroundColor: grayLight,
            zIndex: 1004,
            position: "absolute",
            top: 24,
            left: 24,
          }}
        >
          <CrossIcon color={granite} />
        </IconButton>
      </Zoom>
      <Box sx={greetingTextContainer} flexDirection={"column"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          flexDirection={isNameSplit ? "column" : "row"}
        >
          <GreetingSignText
            namePartFontSize={nameFirstPartFontSize}
            namePartWidth={nameFirstPartWidth}
            passengerName={
              isNameSplit
                ? passengerNameFirstPart
                : `${passengerNameFirstPart} ${passengerNameSecondPart}`
            }
            setNamePartWidth={setNameFirstPartWidth}
            setPaddingTop={isNameSplit}
            finalFontSize={finalFontSize}
          />
          {isNameSplit && (
            <GreetingSignText
              namePartFontSize={nameSecondPartFontSize}
              namePartWidth={nameSecondPartWidth}
              passengerName={passengerNameSecondPart}
              setNamePartWidth={setNameSecondPartWidth}
              setPaddingBottom={isNameSplit}
              finalFontSize={finalFontSize}
            />
          )}
        </Box>
        {companyName && (
          <Box sx={{ display: "flex" }}>
            <Typography fontSize={30}>{companyName}</Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}

export default GreetingSignDrawer;
