import React from "react";
import join from "lodash/join";
import map from "lodash/map";
import pickBy from "lodash/pickBy";

import { Box, Typography } from "@mui/material";

type LuggageBlockProps = {
  luggage: {
    carryOnLuggage: number;
    checkedLuggage: number;
    oversizeLuggage: number;
  };
};

const LuggageBlock = (props: LuggageBlockProps) => {
  const { luggage } = props;

  let luggageString = join(
    map(
      pickBy(luggage, (luggageCount) => !!luggageCount),
      (luggageCount, luggageType) => {
        return luggageCount + " " + mapLuggageToText[luggageType];
      }
    ),
    ", "
  );

  return (
    <Box display="flex" flexDirection="row">
      <Typography variant="body2" fontWeight="bold">
        Luggage: &nbsp;
      </Typography>
      <Typography variant="body2"> {luggageString}</Typography>
    </Box>
  );
};

const mapLuggageToText = {
  carryOnLuggage: "Carry-on",
  checkedLuggage: "Checked",
  oversizeLuggage: "Oversize",
};

export default LuggageBlock;
