import { RoundChipProps } from "components/ui/RoundChip";
import { PickUpVariant } from "types";

/* Moovs Colors */
// Blues
export const moovsBlue = "#195FE9";
export const moovsBlueDark = "#0044CB";
export const moovsBlueLight = "#739CFF";
export const moovsBlueSelected = "#E5EEFF";
export const moovsBlueHover = "#CCDEFF";
// Grayscale
export const black = "#1E1E1E";
export const granite = "#565656";
export const grayDark = "#999999";
export const grayMedium = "#D3D3D3";
export const grayLight = "#EDEDED";
export const alabaster = "#FAFAFA";
export const white = "#FFFFFF";
export const whiteTransparent = "#FFFFFF00";
// Alert Colors
export const successGreen = "#0C893F";
export const warningYellow = "#FFB301";
export const errorRed = "#D63F49";
// Contextual Colors
export const aqua = "#28B299";
export const green = "#28B668";
export const greenDark = "#17693C";
export const yellow = "#EEE647";
export const yellowDark = "#898423";
export const orange = "#FC881D";
export const orangeDark = "#B26115";
export const redDark = "#960031";
export const purple = "#A444B7";
export const purpleDark = "#3831A0";
export const pink = "#EE64D2";
// Tint Colors
export const tintAqua = "#E9F9F5";
export const tintGreen = "#D0ECDD";
export const tintYellow = "#F8F5B5";
export const tintOrange = "#FEEAD2";
export const tintRed = "#FDECED";
export const tintPurple = "#F6EDF7";
export const tintPink = "#FEE8F7";

/* Dev-friendly Color Palettes */
export const swoopGreen = "#34EC87";

// Invite Status
export const invitePending = "#f37f2d";
export const inviteAccepted = "#6FCF97";

// Request Status Chip Background
export const requestStatusBgNewQuote = "#e9f9f5";
export const requestStatusBgDraft = "#f8f5b5";
export const requestStatusBgEmailSent = "#f6edf7";

// Request Status Chip Text
export const requestStatusTextNewQuote = "#28b299";
export const requestStatusTextDraft = "#898423";
export const requestStatusTextEmailSent = "#a444b7";

// Invoice Status Chip Background
export const invoiceStatusBgPaid = "#D0ECDD";
export const invoiceStatusBgPending = "#F8F5B5";

// Invoice Status Chip Text
export const invoiceStatusTextPaid = "#28B668";
export const invoiceStatusTextPending = "#898423";

// Ride Status Chip Background
export const rideStatusBgDone = "#D0ECDD";
export const rideStatusBgOnLoc = "#E9F9F5";
export const rideStatusBgOTW = "#F6EDF7";
export const rideStatusBgPOB = "#FEE8F7";
export const rideStatusBgConfirmed = "#E5EEFF";
export const rideStatusBgPending = "#F8F5B5";
export const rideStatusBgCancelled = "#FDECED";

// Ride Status Chip Text
export const rideStatusTextDone = "#28B668";
export const rideStatusTextOnLoc = "#28B299";
export const rideStatusTextOTW = "#A444B7";
export const rideStatusTextPOB = "#EE64D2";
export const rideStatusTextConfirmed = "#256EFF";
export const rideStatusTextPending = "#898423";
export const rideStatusTextCancelled = "#EF5259";

/* Component Color Objects */
// Request Status Chip
export const requestStatusLabelColors = {
  NEW_QUOTE: {
    backgroundColor: requestStatusBgNewQuote,
    color: requestStatusTextNewQuote,
  },
  DRAFT: {
    backgroundColor: requestStatusBgDraft,
    color: requestStatusTextDraft,
  },
  EMAIL_SENT: {
    backgroundColor: requestStatusBgEmailSent,
    color: requestStatusTextEmailSent,
  },
};

// Invoice Status Chip
export const invoiceStatusLabelColors = {
  PAID: {
    backgroundColor: invoiceStatusBgPaid,
    color: invoiceStatusTextPaid,
  },
  PENDING: {
    backgroundColor: invoiceStatusBgPending,
    color: invoiceStatusTextPending,
  },
};

// Ride Status Chip
export const rideStatusLabelColors = {
  done: {
    backgroundColor: rideStatusBgDone,
    color: rideStatusTextDone,
  },
  "on-location": {
    backgroundColor: rideStatusBgOnLoc,
    color: rideStatusTextOnLoc,
  },
  otw: {
    backgroundColor: rideStatusBgOTW,
    color: rideStatusTextOTW,
  },
  pob: {
    backgroundColor: rideStatusBgPOB,
    color: rideStatusTextPOB,
  },
  confirmed: {
    backgroundColor: rideStatusBgConfirmed,
    color: rideStatusTextConfirmed,
  },
  pending: {
    backgroundColor: rideStatusBgPending,
    color: rideStatusTextPending,
  },
  cancelled: {
    backgroundColor: rideStatusBgCancelled,
    color: rideStatusTextCancelled,
  },
};

// Pick Up Variant Chip Colors
export const pickUpVariantLabelColors: Record<PickUpVariant, RoundChipProps> = {
  [PickUpVariant.MeetGreet]: {
    color: purple,
    backgroundColor: tintPurple,
  },
  [PickUpVariant.Curbside]: {
    color: black,
    backgroundColor: grayLight,
  },
};

// Colored Label Color Combos
export const blueLabelBackground = moovsBlueSelected;
export const blueLabelText = moovsBlue;
export const purpleLabelBackground = tintPurple;
export const purpleLabelText = purple;
export const greenLabelBackground = tintGreen;
export const greenLabelText = successGreen;
export const redLabelBackground = tintPink;
export const redLabelText = errorRed;
export const orangeLabelText = orange;
export const orangeLabelBackground = tintOrange;
