import React, { useEffect, useState } from "react";
import isNull from "lodash/isNull";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

import { Box, Typography, CircularProgress } from "@mui/material";

import {
  LOAD_OPERATOR_ROUTES_QUERY,
  LOAD_OPERATOR_ROUTES_TOTAL_COUNT_QUERY,
} from "../../globals/graphql";
import { grayLight, white } from "globals/design-system/colors";
import theme from "globals/design-system/theme";
import BottomButton from "components/layout/BottomButton";
import { PayoutsIcon, TripIcon } from "globals/design-system/icons";
import { LOAD_INDIVIDUAL_DRIVER_PAYOUTS } from "globals/graphql/driverPayout.graphql";
import { currency } from "globals/utils/helpers";
import { useDriver } from "globals/hooks/useDriver";
import { useQueryParams } from "globals/hooks/useQueryParams";
import {
  DriverHeader,
  EmptyEarningsList,
  DriverPayoutItem,
  EmptyTripsList,
  RouteListItem,
  TripTabs,
} from "./components";

const BOTTOM_BUTTON_HEIGHT = "76px";

function DriverPage() {
  const queryParams = useQueryParams();
  const history = useHistory();

  const tab = queryParams.get("tab_name");
  const navigate = queryParams.get("navigate");
  const { driverId } = useParams<{ driverId: string }>();

  const hideBottomButton = navigate === "hidden";

  // states
  const [mode, setMode] = useState<"trips" | "earnings">(
    tab === "EARNINGS" ? "earnings" : "trips"
  );

  // redirects to first tab if no query string provided
  useEffect(() => {
    if (!tab) history.replace({ search: "?tab_name=NEW" });
  }, [tab, history]);

  // set mode if tab_name changes from trips
  useEffect(() => {
    if (tab !== "EARNINGS") {
      setMode("trips");
    } else {
      setMode("earnings");
    }
  }, [tab, setMode]);

  // hooks
  const { driver, driverLoading, driverError } = useDriver();

  // queries
  const { data: operatorRoutesCountData, error: operatorRoutesCountError } =
    useQuery(LOAD_OPERATOR_ROUTES_TOTAL_COUNT_QUERY, {
      variables: {
        driverId,
      },
      skip: !driver,
      fetchPolicy: "cache-and-network",
    });

  const {
    data: operatorRoutesData,
    error: operatorRoutesError,
    loading: operatorRoutesLoading,
  } = useQuery(LOAD_OPERATOR_ROUTES_QUERY, {
    variables: {
      driverId,
      tabName: tab,
    },
    fetchPolicy: "cache-and-network",
    skip: !driver || !tab || tab === "EARNINGS",
  });

  const {
    data: driverPayoutsData,
    error: driverPayoutsError,
    loading: driverPayoutsLoading,
  } = useQuery(LOAD_INDIVIDUAL_DRIVER_PAYOUTS, {
    variables: {
      driverId,
    },
    fetchPolicy: "cache-and-network",
    skip: !driverId,
  });

  const operatorRoutes = (operatorRoutesData?.operatorRoutes?.edges || []).map(
    ({ node }) => node
  );

  const driverPayouts = driverPayoutsData?.individualDriverPayouts || [];
  const driverPayoutsTotalAmt = driverPayouts.reduce(
    (totalAmt, payout) => totalAmt + payout.totalAmt,
    0
  );

  // event handlers
  const handleOperatorRouteClick = (operatorRouteId: string) => {
    history.push(`/driver/${driverId}/dispatch/${operatorRouteId}`);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, tabName: string) => {
    history.push({ search: `?tab_name=${tabName}` });
  };

  if (
    driverError ||
    driverPayoutsError ||
    operatorRoutesCountError ||
    operatorRoutesError ||
    isNull(driver) ||
    (operatorRoutesCountData &&
      operatorRoutesCountData.operatorRoutesTotalCount === null) ||
    (operatorRoutesData && operatorRoutesData.operatorRoutes === null)
  ) {
    return (
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        flex="1"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2">Server Error</Typography>
        <Typography variant="body1">
          Please check the provided url string.
        </Typography>
      </Box>
    );
  }

  const handleModeToggle = () => {
    history.push({
      search: `?tab_name=${tab !== "EARNINGS" ? "EARNINGS" : "NEW"}`,
    });
  };

  if (driverLoading) {
    return (
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        flex="1"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  const modeMap = (mode: "trips" | "earnings") =>
    ({
      trips: {
        headerComponent: (
          <TripTabs
            onTabChange={handleTabChange}
            tab={tab}
            operatorRoutesTotalCount={
              operatorRoutesCountData?.operatorRoutesTotalCount
            }
          />
        ),
        bottomButtonText: "View My Earnings",
        bottomButtonIcon: <PayoutsIcon color={white} />,
        listItems: operatorRoutes,
        listItemComponent: (route) => (
          <RouteListItem
            route={route}
            onRouteClick={() => handleOperatorRouteClick(route.id)}
          />
        ),
        emptyListComponent: <EmptyTripsList activeTab={tab} />,
      },
      earnings: {
        headerComponent: (
          <Box
            height="71px"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            pb={2}
            px={2}
            maxWidth="600px"
            mx="auto"
          >
            <Typography variant="h2">Earnings</Typography>
            <Typography variant="h3">
              {currency(driverPayoutsTotalAmt)}
            </Typography>
          </Box>
        ),
        bottomButtonText: "View My Trips",
        bottomButtonIcon: <TripIcon color={white} />,
        listItems: driverPayouts,
        listItemComponent: (payout) => (
          <DriverPayoutItem mode="driverPayout" payout={payout} />
        ),
        emptyListComponent: <EmptyEarningsList />,
      },
    }[mode]);

  const {
    headerComponent,
    bottomButtonIcon,
    bottomButtonText,
    listItems,
    listItemComponent,
    emptyListComponent,
  } = modeMap(mode);

  const DriverPageList = () =>
    listItems.length > 0 ? (
      listItems.map((item, index) => (
        <Box key={index} p={1} maxWidth={theme.breakpoints.values.sm} mx="auto">
          {listItemComponent(item)}
        </Box>
      ))
    ) : (
      <Box mt={11} mx="auto" maxWidth={theme.breakpoints.values.sm}>
        {emptyListComponent}
      </Box>
    );

  return (
    <>
      <Helmet>
        <title>{`${driver.firstName} ${driver.lastName} | Moovs`}</title>
      </Helmet>
      <Box height="100%" margin={0}>
        <Box
          position="sticky"
          top={0}
          zIndex={1001}
          bgcolor={theme.palette.background.paper}
        >
          <DriverHeader driver={driver} />
          <Box borderBottom={`1px solid ${grayLight}`}>{headerComponent}</Box>
        </Box>
        <Box>
          {driverPayoutsLoading || operatorRoutesLoading ? (
            <Box display="flex" justifyContent="center" m={2}>
              <CircularProgress />
            </Box>
          ) : (
            <DriverPageList />
          )}

          {/* Empty space for room to scroll w/o being blocked by BottomButton */}
          <Box height={BOTTOM_BUTTON_HEIGHT} />
        </Box>

        {!hideBottomButton && (
          <BottomButton
            text={bottomButtonText}
            icon={bottomButtonIcon}
            onClick={handleModeToggle}
          />
        )}
      </Box>
    </>
  );
}

export default DriverPage;
