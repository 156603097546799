import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "@apollo/client";
import first from "lodash/first";

import {
  Box,
  Divider,
  Button,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";

import {
  LOAD_OPERATOR_ROUTE_QUERY,
  DRIVER_CLOSEOUT_ROUTE_MUTATION,
} from "globals/graphql";
import theme from "globals/design-system/theme";
import checkSymbol from "globals/design-system/illustrations/checkSymbol.svg";
import { applyUTCOffsetToTime } from "globals/utils/helpers";
import { getEndDateTimeForRouteFeedback } from "./util/getDateTimeForRouteFeedback";
import MoovsDateTimePicker from "../../components/ui/MoovsDateTimePicker";

function RouteFeedbackPage() {
  const { operatorRouteId, driverId } = useParams<{
    operatorRouteId: string;
    driverId: string;
  }>();
  // react router
  const history = useHistory();

  // state
  const [startDateTime, setStartDateTime] = useState<string>(""); // ISOString
  const [endDateTime, setEndDateTime] = useState<string>(""); // ISOString
  const [driverComment, setDriverComment] = useState("");

  // queries
  const {
    data: operatorRouteData,
    loading: operatorRouteLoading,
    error: operatorRouteError,
  } = useQuery(LOAD_OPERATOR_ROUTE_QUERY, {
    variables: {
      operatorRouteId: operatorRouteId,
    },
    skip: !operatorRouteId,
  });

  // mutation
  const [driverCloseoutRoute] = useMutation(DRIVER_CLOSEOUT_ROUTE_MUTATION, {
    onCompleted() {
      redirectToDriverPage();
    },
  });

  // event handlers
  const redirectToDriverPage = () => {
    history.push(`/driver/${driverId}/`);
  };

  const handleSubmitClick = () => {
    driverCloseoutRoute({
      variables: {
        input: {
          routeId: operatorRouteId,
          driverCloseoutStartDateTime: startDateTime,
          driverCloseoutEndDateTime: endDateTime,
          driverCloseoutNote: driverComment,
        },
      },
    });
  };

  // derived state
  const { name } = operatorRouteData?.driverRoute?.operator || {};

  const operatorRoute = operatorRouteData?.driverRoute;

  const confirmationNumber = operatorRoute?.trip?.tripNumber
    ? `${operatorRoute?.request?.orderNumber}-${operatorRoute?.trip?.tripNumber}`
    : operatorRoute?.request?.orderNumber;

  const customerEndDateTime =
    operatorRoute && getEndDateTimeForRouteFeedback(operatorRoute);
  const { dateTime: customerStartDateTime } =
    first(operatorRoute?.trip.stops) || {};

  // time adjust for DateTime Picker
  const adjustedStartDateTime = useMemo(
    () =>
      startDateTime
        ? applyUTCOffsetToTime(startDateTime, "add").toISOString()
        : null,
    [startDateTime]
  );

  const adjustedEndDateTime = useMemo(
    () =>
      endDateTime
        ? applyUTCOffsetToTime(endDateTime, "add").toISOString()
        : null,
    [endDateTime]
  );

  // set initial dateTimes
  useEffect(() => {
    if (
      !startDateTime &&
      !endDateTime &&
      customerStartDateTime &&
      customerEndDateTime
    ) {
      setStartDateTime(customerStartDateTime);
      setEndDateTime(customerEndDateTime);
    }
  }, [customerStartDateTime, customerEndDateTime, startDateTime, endDateTime]);

  // redirects to routePage if should not be in feedback
  // this could be useful if operator manually sets a ride
  // back to in progress after it was marked done by operator
  // a bit of an escape hatch.
  const notInFeedback = useMemo(
    () =>
      operatorRoute &&
      (operatorRoute?.statusSlug !== "done" ||
        !!operatorRoute?.driverCloseoutStartDateTime),

    [operatorRoute]
  );

  useEffect(() => {
    if (!!notInFeedback) {
      history.replace(`/driver/${driverId}/dispatch/${operatorRouteId}`);
    }
  }, [history, notInFeedback, driverId, operatorRouteId]);

  if (
    operatorRouteError ||
    (operatorRouteData && operatorRouteData.node === null)
  ) {
    return (
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        flex="1"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2">Server Error</Typography>
        <Typography variant="body1">
          Please check the provided url string.
        </Typography>
      </Box>
    );
  }

  if (operatorRouteLoading) {
    return (
      <Box
        position="fixed"
        display="flex"
        flexDirection="column"
        flex="1"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{name || "Moovs Driver"}</title>
      </Helmet>
      <Box
        display="flex"
        minHeight="100%"
        flexDirection="column"
        margin={0}
        position="relative"
      >
        <Box display="flex" flex="1" justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            maxWidth={theme.breakpoints.values.sm}
          >
            <Box my={3} mx={2}>
              <Box mb={1.5}>
                <img src={checkSymbol} alt="check symbol" />
              </Box>
              <Typography variant="h2">
                {`Trip ${confirmationNumber} is now done!`}
              </Typography>
              <Box mt={1}>
                <Typography variant="body2">
                  Please complete the following information to close out the
                  ride
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box m={2}>
              {/* start time */}
              <Box mb={2}>
                <Typography variant="h3">Start Time</Typography>
              </Box>
              <MoovsDateTimePicker
                label="Start Time"
                openTo="hours"
                value={adjustedStartDateTime}
                onAccept={(dateTime) =>
                  setStartDateTime(
                    applyUTCOffsetToTime(dateTime, "subtract").toISOString()
                  )
                }
                renderInputProps={{
                  name: "pickupDateTime",
                }}
              />
              {/* end time */}
              <Box mt={2} mb={3}>
                <Typography variant="h3">End Time</Typography>
              </Box>{" "}
              <MoovsDateTimePicker
                label="End Time"
                openTo="hours"
                value={adjustedEndDateTime}
                minDate={adjustedStartDateTime}
                onAccept={(dateTime) =>
                  setEndDateTime(
                    applyUTCOffsetToTime(dateTime, "subtract").toISOString()
                  )
                }
                renderInputProps={{
                  name: "dropoffDateTime",
                }}
              />
              {/* driver comments */}
              <Box mt={2} mb={3}>
                <Typography variant="h3">Driver Comments</Typography>
              </Box>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Please provide any comments about the trip here"
                multiline
                rows={2}
                maxRows={8}
                inputProps={{ maxLength: 3000 }}
                value={driverComment}
                onChange={(e) => setDriverComment(e.target.value)}
              />
              <Box display="flex" justifyContent="flex-end" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitClick}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default RouteFeedbackPage;
