/**
 * @file TripPlannerBlock.tsx
 * Trip Planner section of driver dispatch view
 *
 * components:
 *  TripPlannerBlock
 */
import React, { useState } from "react";

import {
  Box,
  Typography,
  Divider,
  Drawer,
  ButtonBase,
  Card,
} from "@mui/material";

import { grayDark } from "globals/design-system/colors";
import { Stop } from "types";
import { CrossIcon } from "globals/design-system/icons";
import theme from "globals/design-system/theme";
import { StopItem } from "./components";

type TripPlannerBlockProps = {
  stops: Stop[];
};

function TripPlannerBlock(props: TripPlannerBlockProps) {
  const { stops } = props;

  // state
  const [stopDetailsIndex, setStopDetailsIndex] = useState(null);

  const getStopLabel = (stopIndex: number) => {
    if (stopIndex === 1) return "Pick-up";
    if (stopIndex === stops.length) return "Drop off";

    return `Stop ${stopIndex - 1}`;
  };

  // custom Styles
  const drawerSubTitleCustomStyle = { fontWeight: 500, color: grayDark };

  return (
    <>
      <Box mb={2}>
        <Box mb={2}>
          <Typography variant="h3">Trip Planner</Typography>
        </Box>
        <Card variant="outlined">
          <Box my={2} px={2}>
            {stops.map((stop: Stop, index) => {
              const isLastStop = stop.stopIndex === stops.length;

              return (
                <Box key={stop.id}>
                  <StopItem
                    stop={stop}
                    label={getStopLabel(stop.stopIndex)}
                    onStopDetailsClick={() => setStopDetailsIndex(index)}
                  />
                  {!isLastStop && <Divider />}
                </Box>
              );
            })}
          </Box>
        </Card>
      </Box>

      {/* Stop Details Drawer*/}
      <Drawer
        anchor="bottom"
        open={stopDetailsIndex !== null}
        onClose={() => setStopDetailsIndex(null)}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "14px",
            borderTopRightRadius: "14px",
          },
        }}
      >
        <Box display="flex" flex="1" justifyContent="center">
          <Box
            m={2}
            display="flex"
            flex="1"
            justifyContent="center"
            flexDirection="column"
            maxWidth={theme.breakpoints.values.sm}
          >
            <Box
              height="34px"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              flex="1"
            >
              <Typography variant="h3">Stop Details</Typography>
              <Box height="24px">
                <ButtonBase onClick={() => setStopDetailsIndex(null)}>
                  <CrossIcon />
                </ButtonBase>
              </Box>
            </Box>
            <Box mt={2} mb={1}>
              <Typography variant="body2" sx={drawerSubTitleCustomStyle}>
                Total Stop Group Size
              </Typography>
            </Box>
            <Typography variant="body2">
              {(stops[stopDetailsIndex] && stops[stopDetailsIndex].groupSize) ||
                "-"}
            </Typography>
            <Box mt={2} mb={1}>
              <Typography variant="body2" sx={drawerSubTitleCustomStyle}>
                Stop Notes
              </Typography>
            </Box>
            <Box mb={1.5}>
              <Typography variant="body2">
                {(stops[stopDetailsIndex] && stops[stopDetailsIndex].note) ||
                  "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default TripPlannerBlock;
